import React, { useEffect, useState } from "react";
import { TopNavbar } from "../Navbar/TopNavbar";
import "./Home.scss";
import { Link } from "react-scroll";
import WOW from "wow.js";

export const Home = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const handleMenuItemClick = (menuItemId, event) => {
    event.preventDefault(); // Prevent default link behavior
    setActiveMenuItem(menuItemId);
  };
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      setActiveMenuItem(null); // Disable active state when scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 
  return (
    <>
      <div
        className="home-background"
        id="home"
        // style={{
        //   background:"#000"
        //   // background:
        //   //   "radial-gradient(circle, rgba(79,77,77,1) 0%, rgba(79,79,79,1) 0%, rgba(0,0,0,1) 77%, rgba(0,0,0,1) 100%)",
        // }}
      >
        {/* content starts */}
        <div className="mb-10  flex flex-col   items-center justify-center text-white font-black text-center">
          <TopNavbar />
          <div className="text-6xl normal-text mt-40 wow fadeInUp home" id="home">
            <span
            className=" font-sans"
              style={{
                background:
                  "-webkit-linear-gradient(153deg, rgba(0,240,255,1) 0%, rgba(82,0,255,1) 52%, rgba(255,45,247,1) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
               You Dream It,
            </span>
            <span className="wow fadeInUp text-shadow-home font-sans"> We Build It.</span>
          </div>
          <div className="lg:text-3xl text-2xl  font-sans lg:mt-4 mt-2 wow fadeInUp text-shadow-home vision mx-3">
          Your Vision, Our Code - Software Brilliance Defined!
          </div>
        </div>
        <div className="text-white font-sans font-semibold text-center ">
          <div className="corvanta-text  Corvanta-font  font-mono font-black text-animation-corvanta ">CORVANTA</div>
          <div className="text-xl font-normal lg:-mt-16 sm:mt-1 pb-4  mx-4 wow fadeInUp">
            Giving life to your ideas in the borld of bits and bytes.
          </div>
        </div>
      </div>
      
      {/* content ends */}
      <div className="banner font-sans bg-[#EF3B3B]">
        <div className="background text-5xl text-center font-semibold py-4 w-full">
          <div className="item">- PRODUCT DEVELOPMENT </div>
          <div className="item">- UX & UI DESIGN</div>
          <div className="item">- APPLICATION DEVELOPMENT </div>
          <div className="item">- CLOUD COMPUTING </div>
          <div className="item">- ARTIFICIAL INTELLIGENCE </div>
        </div>
    </div>
      <div className="hidden lg:block font-sans">
        <div className="horizontal-line mt-40  flex-col">
          <Link
            to="home"
            spy={true}
            smooth={true}
            onClick={(event) => handleMenuItemClick("home",event)}
            className={
              activeMenuItem === "home" ? "menu-item active" : "menu-item"
            }
          >
            <div className="menu-item ">
              <div className="line"></div>
              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">Steer Your Destiny</div>
            </div>
          </Link>
          <Link to="whycorvanta" spy={true} smooth={true}
             onClick={(event) => handleMenuItemClick("whycorvanta",event)}
             className={
               activeMenuItem === "whycorvanta" ? "menu-item active" : "menu-item"
             }
          >
            <div className="menu-item ">
              <div className="line"></div>

              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">Our Inside Scoop</div>
            </div>
          </Link>
          <Link to="projects" spy={true} smooth={true}
           onClick={(event) => handleMenuItemClick("projects",event)}
           className={
             activeMenuItem === "projects" ? "menu-item active" : "menu-item"
           }>
            <div className="menu-item ">
              <div className="line"></div>

              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">Power Prowess</div>
            </div>
          </Link>
          <Link to="easyflow" spy={true} smooth={true}
          onClick={(event) => handleMenuItemClick("easyflow",event)}
          className={
            activeMenuItem === "easyflow" ? "menu-item active" : "menu-item"
          }>
            <div className="menu-item ">
              <div className="line"></div>

              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">Action Matrix</div>
            </div>
          </Link>
          <Link to="nearfuture" spy={true} smooth={true}
            onClick={(event) => handleMenuItemClick("nearfuture",event)}
            className={
              activeMenuItem === "nearfuture" ? "menu-item active" : "menu-item"
            }>
            
            <div className="menu-item ">
              <div className="line"></div>

              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">The Near Future</div>
            </div>
          </Link>
          <Link to="contactus" spy={true} smooth={true}
          onClick={(event) => handleMenuItemClick("contactus",event)}
          className={
            activeMenuItem === "contactus" ? "menu-item active" : "menu-item"
          }>
            <div className="menu-item ">
              <div className="line"></div>
              <div className="menu-item-name text-white w-20 mt-1 text-left ml-10">Contact Us</div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
