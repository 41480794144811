import React from "react";
import { trefoil } from "ldrs";
trefoil.register();

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <div>
        {/* Your loader animation */}
        <l-trefoil size="80" stroke="5" speed="1" color="#ffffff"></l-trefoil>
      </div>
    </div>
  );
};

export default Loader;
