import React from "react";
import icons from "../Images/SVGRepo_iconCarrier.svg";
import Futures from "../Images/Future.png";
export const Refinding = () => {

    const titles = [
        {
            title: "Al-enabled provider transcripts"
        },
        {
            title: "Al-powered symptom checker"
        },
        {
            title: "Sentiment analysis"
        },
        {
            title: "Virtual care assistant with OpenAI’s LLM"
        },

        {
            title: "NLP-based clinical notes interpretation"
        },
        {
            title: "Automatically suggesting appropriate billing codes"
        },
        {
            title: "Seamless TTS/STT integration"
        },
        {
            title: "Autonomous intake form generation"
        },
        {
            title: "AWS cloud for scalable deployment"
        },
        {
            title: "Secure info management with AWS Secrets Manager"
        },


    ]

    return (
        <div className="font-sans  mb-1 lg:mb-14 p-4" style={{ backgroundImage: `url(${Futures})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            id="services">
            <p className="flex justify-center text-4xl font-sans sm:text-4xl lg:text-5xl text-[#EF3B3B]  font-semibold text-start wow fadeInUp mt-6">
                <span className="flex justify-center flex-col  font-bold items-center mt-3">
                    <svg
                        width="129"
                        height="27"
                        viewBox="0 0 129 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-6"
                    >
                        <rect width="4" height="27" fill="white " />
                        <rect x="25" width="4" height="27" fill="white" />
                        <rect x="50" width="4" height="27" fill="white" />
                        <rect x="75" width="4" height="27" fill="white" />
                        <rect x="100" width="4" height="27" fill="white" />
                        <rect x="125" width="4" height="27" fill="white" />
                    </svg>

                </span>
            </p>
            <div className="lg:mx-24">
                <div className="lg:text-4xl  font-geo text-2xl font-bold wow fadeInUp text-[#EF3B3B] mt-1 mb-5">
                    Redefining Tech Solutions fusing AI-Enabled Healthcare
                </div>
                <div className="lg:text-2xl text-xl font-bold wow fadeInUp text-gray-200 mt-1 mb-5">
                    Integrations of Al in healthcare solutions is aiding in increasing patient care, patient engagement and streamlining processes.
                </div>
                <div className="text-center text-white font-bold text-xl">
                    We help health tech companies leverage Al through:
                </div>
                <div className="grid grid-cold-1 lg:grid-cols-2 mt-6 gap-6 py-10 wow fadeInUpOur core strength l">
                    {titles.map((text, index) => (
                        <div className="flex items-center justify-start gap-4">
                            <div className="">
                                <img src={icons} alt="" className="h-4" />
                            </div>
                            <div className="text-left text-white text-xl font-medium">
                                {text.title}
                            </div>
                        </div>

                    ))}
                </div>
            </div>




        </div>
    );
};
