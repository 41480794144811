// src/segment.js
import { Analytics } from '@segment/analytics-next';

const writeKey = 'UzhKVndvTbLn843nSvEtNmBFZStHZuxG'

const analytics = new Analytics({
  writeKey,
});

export const segment = (event,properties) => {
  window.analytics.track(event,properties);
}

export default analytics;