import React from "react";
import { Link } from "react-scroll";
import "./Future.scss";
import left from "../Images/bleft.png";
import Futures from "../Images/Future.png";
import svgg from "../Images/Corvanta About.png";

export const Future = () => {
  return (
    <div id="future" className="p-5 lg:p-1 " style={{ backgroundImage: `url(${Futures})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="p-15  flex flex-col lg:flex-row lg:justify-between lg:items-center text-white font-semibold font-sans">
        <div className="lg:w-1/2 text-left lg:ml-28 mt-20">
          <div className="flex justify-left flex-col items-start">
            <svg
              width="129"
              height="27"
              viewBox="0 0 129 27"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-6"
            >
              <rect width="4" height="27" fill="#ffffff" />
              <rect x="25" width="4" height="27" fill="#ffffff" />
              <rect x="50" width="4" height="27" fill="#ffffff" />
              <rect x="75" width="4" height="27" fill="#ffffff" />
              <rect x="100" width="4" height="27" fill="#ffffff" />
              <rect x="125" width="4" height="27" fill="#ffffff" />
            </svg>
          </div>

          <div className="lg:text-4xl text-2xl  font-geo font-bold text-[#EF3B3B] mb-5">
            Build your Intelligent Futures with AI Software Development<br />Company
          </div>
          <div className="text-white font-medium lg:text-xl text-lg mx-4 wow slideInLeft">
            Maximize your business capabilities with our AI development services, crafted to simplify your business processes and enhance operational productivity. From AI advisory to creating bespoke AI applications and seamlessly integrating them into your operations, we provide a complete range of AI solutions. Additionally, we specialize in refining core models such as GPT, Llama, PaLM, Mistral, and Gemini to develop personalized, industry-specific models that align with your specific business needs.
          </div>
          <div className='text-lg mt-14'>
          <Link
                to="contactus"
                spy={true}
                smooth={true}
                className='button py-3 px-4 hover:text-white' style={{
                  background: "linear-gradient(to right, black 50 %, white 50 %)"
                }}>Book Your Call</Link>
          </div>
        </div>
        
        <div className="lg:w-1/2 flex justify-center mt-10 lg:mt-0 lg:mr-28 wow slideInRight ">
          <img src={svgg} alt=" " className="w-full lg:w-auto" />
        </div>
      </div>
    </div>
  );
};

export default Future;
