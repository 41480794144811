import React, { useEffect, useState } from "react";
import "./App.scss";
import './index.scss';
import { Landing } from "./Landing/Landing";
import Loader from "./Loader";
import "wow.js/css/libs/animate.css";
import WOW from "wow.js";

function App() {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate content loading delay (remove this in production)
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <>
    {loading ? (
      <Loader/> // Show the loader while content is loading
    ) : (
    <div className="App">
      {/* <TopNavbar /> */}
      <Landing />
    </div>
    )} 
    </>
    
  );
}

export default App;
