import React from 'react';
import Lottie from 'react-lottie';
import animationData from "./Animation - 1711967976973.json";

export default function Animation() {
  const isMobileView = window.innerWidth <= 1260;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    const width = isMobileView ? 200 : 250;
    const height = isMobileView ? 200 : 250;

    return (
      <div>
        <Lottie 
          options={defaultOptions}
          height={height}
          width={width}
          style={{ pointerEvents: 'none' }}
        />
      </div>
    );
  }
 