import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import akkas from "../Images/2akka.png";
import product from "../Images/Product Icons.png";
import "./Careers.scss";
import thamim from "../Images/thamim.jpeg";
import javid from "../Images/javid.jpeg";
import sameer from "../Images/sammer.jpeg";
import jegan from "../Images/jegan.jpeg";
import jaga from "../Images/jegadesh.jpeg";
import anu from "../Images/anu.jpeg";
import fawaz from "../Images/fawaz.jpeg";
import Ishwarya from "../Images/isha.jpeg";
import Kanaga from "../Images/kanaga.jpeg";
import rifana from "../Images/rifana.jpeg";
import deepika from "../Images/deepika.jpeg";
import mubben from "../Images/mubeen.jpeg";
import dhaiya from "../Images/dhayiya.jpeg"

const testimonials = [
  {
    avatar: javid,
    name: "Javid Nashraf",
    review: "After joining the company, my career trajectory has completely changed. The supportive environment and growth opportunities are exceptional. I can't imagine working anywhere else!"
  },
  {
    avatar: thamim,
    name: "Thamim Ansari",
    review: "Joining this company has been a game-changer for my professional life. The culture here fosters innovation and collaboration. I'm proud to be part of such a forward-thinking organization."
  },
  {
    avatar: sameer,
    name: "Mohammed Sameer",
    review: "Working here has been an amazing experience. The company's commitment to employee development is evident in everything they do. I highly recommend it to anyone looking for a fulfilling career."
  },
  {
    avatar: jegan,
    name: "Jaganathan Sundaraj",
    review: "Life at this company is truly rewarding. The leadership team is approachable, and the opportunities for growth are abundant. I couldn't have asked for a better place to work."
  },
  {
    avatar: Ishwarya,
    name: "Ishwarya",
    review: "After joining the company, I've found a perfect balance between work and personal life. The supportive culture and amazing colleagues make every day enjoyable."
  },
  {
    avatar: jaga,
    name: "Jegathesh Moorthy",
    review: "This company has transformed my career. The innovative projects and collaborative atmosphere have pushed me to new heights. I'm excited for the future here."
  },
  {
    avatar: mubben,
    name: "Mohammed Mubeen Azath",
    review: "Joining this company was the best decision I've ever made. The growth opportunities are endless, and the support from the team is unparalleled."
  },
  {
    avatar: rifana,
    name: "Rifana",
    review: "The company has exceeded my expectations in every way. From career development to work-life balance, it offers everything I was looking for in an employer."
  },
  {
    avatar: fawaz,
    name: "Mohammed Fowaz",
    review: "Since joining, I've felt a strong sense of belonging and purpose. The company's vision and values resonate with me, making it a joy to come to work every day."
  },
  {
    avatar: deepika,
    name: "Deepika Kandaswamy",
    review: "Working here has been a transformative experience. The company's dedication to employee growth and wellbeing is truly remarkable. I highly recommend it to anyone seeking a fulfilling career."
  },
  {
    avatar: dhaiya,
    name: "Dhahi Ya",
    review: "The supportive environment and fantastic growth opportunities have made my time here incredibly rewarding. I'm proud to be part of such a dynamic team."
  },
  {
    avatar: Kanaga,
    name: "Kanaga",
    review: "Joining this company has been the highlight of my career. The collaborative culture and innovative projects make every day exciting and fulfilling."
  },
  {
    avatar: anu,
    name: "Anu",
    review: "Since joining, I've experienced unmatched professional growth. The company's commitment to excellence and employee satisfaction is truly inspiring. I couldn't be happier."
  }
];


export const Career = () => {
  const swiperRef = useRef(null);

  const updateSlidesPerView = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      if (window.innerWidth < 501) {
        swiper.params.slidesPerView = 1;
      } else if (window.innerWidth < 724) {
        swiper.params.slidesPerView = 2;
      } else {
        swiper.params.slidesPerView = 2.3;
      }
      swiper.update();
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);
    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  return (
    <div
      className="font-sans py-10 flex flex-col justify-center items-center"
      id="Values"
      style={{
        backgroundImage: `url(${akkas})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col items-center gap-2">
        <div className="flex items-center gap-2">
          <div>
            <img src={product} alt="Product Icon" />
          </div>
          <div className=" font-sans text-5xl font-bold text-left wow fadeInUp text-[#EF3B3B]">
            Careers
          </div>
        </div>
        <div className="text-semibold mt-4 text-center text-3xl text-gray-200 wow fadeInUp p-3">
          Every individual in Corvanta is the navigator of our clients’ digital transformation
        </div>
      </div>
      <div id="craouselContainer" className="swiper-container mt-10">
        <Swiper
          ref={swiperRef}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          spaceBetween={30}
          effect="coverflow"
          modules={[EffectCoverflow, Pagination, Autoplay]}
          coverflowEffect={{
            rotate: 0,
            depth: 800,
            slideShadows: true,
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          autoplay={{ delay: 1000 }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="ImgHolder">
                <img src={testimonial.avatar} alt={testimonial.name} />
              </div>
              <div className="ContentHolder rounded-xl ">
                <h3 className='font-geo'>{testimonial.name}</h3>
                <p className='font-sans'>{testimonial.review}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
};
