import React, { useEffect,useState } from "react";
import About from "../Images/Summit.png";
import Summit2 from "../Images/summit2.png";
import WOW from "wow.js";
import { IconBrandLinkedin } from "@tabler/icons-react";

export const WhyCorvanta = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const wow = new WOW();
    wow.init();
    
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div
      className="w-full font-sans "
      id="whycorvanta"
      // style={{
      //   background:
      //     "linear-gradient(90deg, rgba(147,109,247,0.9389005602240896) 0%, rgba(138,227,255,1) 100%)",
      // }}
    >
      <div
        className="section"
        id="aboutus"
        style={{ textAlign: "justify", textJustify: "auto" }}
      >
        <div className="sm:px-2">
          <div className="px-10 flex flex-col lg:flex-row justify-around items-start lg:items-center">
          <div className="image  wow slideInLeft mb-6">
              {/* {isMobile ? (
                <img
                  className="mt-4 ml-1 lg:mt-8 w-full mb-4 lg:mb-8 h-96 rounded-2xl wow slideInLeft"
                  src={Summit2}
                  alt="Logo Image"
                />
              ) : ( */}
                <img
                  className="mt-4 ml-1 lg:mt-8 w-full mb-4 lg:mb-8 h-96 rounded-2xl wow slideInLeft"
                  src={About}
                  alt="Logo Image"
                />
              {/* )} */}
              <div className="flex gap-2 ">
              <div className="font-bold text-white text-xl">
              Raja Mohammed,
              </div>
              <div className=" ">
                <a
                  href="https://www.linkedin.com/in/rajamohd/" 
                  label="LinkedIn"
                >    <IconBrandLinkedin size={25} style={{ color: "#FFFFFF" }} /></a>
              </div>
              </div>
             
              <div className="text-gray-300 font-semibold text-lg">
              Founder and CEO
              </div>
            </div>


            <div className="w-full lg:w-1/2 flex flex-col justify-start items-start lg:items-start">
             
              <div className="wow slideInRight lg:p-10">
              <span className="">
                <svg
                  width="129"
                  height="27"
                  viewBox="0 0 129 27"
                  fill="#ffffff"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mb-6"
                >
                  <rect width="4" height="27" fill="#ffffff" />
                  <rect x="25" width="4" height="27" fill="#ffffff" />
                  <rect x="50" width="4" height="27" fill="#ffffff" />
                  <rect x="75" width="4" height="27" fill="#ffffff" />
                  <rect x="100" width="4" height="27" fill="#ffffff" />
                  <rect x="125" width="4" height="27" fill="#ffffff" />
                </svg>
              </span>
                <div className="text-5xl  font-geo text-[#EF3B3B] flex justify-start font-bold mb-2 lg:mb-6 self-start wow fadeInUp">
                  About Us
                </div>
                <div className="text-lg  text-white  text-left font-medium  mb-2 lg:mb-6" >
                  Hailing from the vibrant city of Chennai, we're not just another Software Development Company
                </div>
                <div className="lg:text-lg text-[15px]  text-white font-normal text-left mb-2 lg:mb-6 ">
                 We're AI practitioners redefining software development with a touch of brilliance. We are driven by a passion for excellence and a commitment to delivering products to make you stand out from the crowd.
                <div className="mt-2 text-justify leading-normal">
                We take pride in our ability to understand the intricacies of diverse industries, allowing us to tailor our services to your specific requirements. Whether you're a startup or a big company, we are with you to make your ideas happen. Step into the sphere of bits and bytes where we thrive to connect people and possibilities.
                </div>
                </div>
                <div className="text-lg wow fadeInInUp text-white font-medium leading-relaxed mb-2 lg:mb-6">
                  Get ready for innovation that cares!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
