import React, { useRef, useEffect } from 'react';
import { Home } from '../Components/Home/Home'
import { WhyCorvanta } from '../Components/WhyCorvanta/WhyCorvanta'
import { EasyFlow } from '../Components/EasyFlow/EasyFlow'
import { Contact } from '../Components/Contact/Contact'
import Footer from '../Components/Footer/Footer'
import { Projects } from '../Components/Projects/Projects'
import { Privacy } from '../Components/Privacy/Privacy'
import { Cards } from '../Components/Cards/Cards';
import Future from '../Components/Future/Future';
import { Refinding } from '../Components/Refinding Tech/Refineding Tech';
import AiHealth from '../Components/AiHealth/AiHealthcare';
import { ValuesAi } from '../Components/ValueAi/ValuesAi';
import Language from '../Components/TechStack/TechStack';
import { Career } from '../Components/Careers/Career';

export const Landing = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollToRef(scrollRef);
  }, []); // This effect runs only once after the initial render

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <div className="" ref={scrollRef}>
        <Home />
        <Future />
        <Refinding />
        <Projects />
        <AiHealth />
        <EasyFlow />
        <div className="hidden md:block lg:block">
        <ValuesAi />
        </div>
        <Language/>
        <Cards />
        <WhyCorvanta />
        <Career />
        <Privacy />
        <Contact />
        <Footer />
      </div>
    </>
  );
};
