import React, { useEffect } from "react";
import WOW from "wow.js";
import "./Footer.scss";
import { Link, Link as ScrollLink } from "react-scroll";
import {
  IconBrandFacebook,
  IconBrandFacebookFilled,
  IconBrandLinkedin,
  IconBrandWhatsapp,
  IconBrandXFilled,
  IconLocation,
  IconMail,
  IconMailCheck,
  IconPhoneCall,
} from "@tabler/icons-react";
import nascom from "../Images/NASSCOM Batch.png";


function Footer() {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  const data = [
    {
      text: "Service Offering",
      imageSrc: "image1.jpg",
      alt: "Image 1",
    },
    {
      text: "Service Offering",
      imageSrc: "image2.jpg",
      alt: "Image 2",
    },
    {
      text: "Service Offering",
      imageSrc: "image3.jpg",
      alt: "Image 3",
    },
    {
      text: "Service Offering",
      imageSrc: "image4.jpg",
      alt: "Image 4",
    },
  ];
  return (
    <>
      <footer className="bg-black text-white  bg-fixed font-sans p-2 "
      id="footerinfo">
        <div className="footer flex justify-around flex-wrap">
          <div className="flex  justify-center items-center mt-5  wow fadeInUp">
            <div className="p-2">
              <IconPhoneCall size={40} style={{ color: "#FFFFFF" }} />
            </div>
            <div className="px-3 text-white text-center">
              <a href="tel:+91 9003079382">
              <div className="font-semibold text-lg">+91 9003079382</div>
              <div className=" text-sm flex text-left">
                Hotline 24/7
              </div>
              </a>
              
            </div>
          </div>
          <div className="flex justify-center items-center mt-5 wow fadeInUp">
            <div className="p-2">
              <IconMailCheck size={40} style={{ color: "#FFFFFF" }} />
            </div>
            <div className="px-3 text-white text-center">
              <a href="mailto:Contactus@corvanta.io">
              <div className="font-semibold text-lg wow fadeInUp">
              Contactus@corvanta.io
              </div>
              <div
                className=" text-sm flex text-left"
              >
                Free Consult
              </div>
              </a>
             
            </div>
          </div>
          <div className="flex justify-center items-center mt-5 wow fadeInUp">
            <div className="p-2">
              <IconLocation size={40} style={{ color: "#FFFFFF" }} />
            </div>
            <div className="px-3 text-white text-center">
              <div className="font-semibold text-lg text-nowrap">
                Chamiers Road, Alwarpet
              </div>
              <div className=" text-sm flex text-left">Chennai-600035</div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-7 wow fadeInUp ">
          <ScrollLink to="contactus" smooth={true} >
            <div className="text-lg font-semibold cursor-pointer rounded-full shadow-lg bg-[#1B181A] px-3 p-2 text-white">
              Get in touch &gt;
            </div>
          </ScrollLink>
        </div>
        <div className="mx-10">
          <hr className="my-6 border-[#EF3B3B] sm:mx-auto lg:my-10  " />
        </div>
        <div className="flex justify-between mt-10 mx-4 lg:mx-24 p-5">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 ">
            <div>
              <p className="mb-4 text-left wow fadeInUp">
              We're an AI development company partnering up with clients to make their ideas for apps and digital advancements a reality.
              </p>
              <div className="flex space-x-4 text-left self-start wow fadeInUp ">
                <SocialLink
                  href="https://www.linkedin.com/company/corvanta-analytics"
                  icon={
                    <IconBrandLinkedin size={25} style={{ color: "#FFFFFF" }} />
                  }
                  label="LinkedIn"
                />
              </div>
            </div>
              <div className="text-left flex flex-col lg:items-center sm:items-start">
              <h4 className="text-lg mr-14 flex flex-col font-bold mb-2 wow fadeInUp ">Pages</h4>
              <ul className="font-normal text-base cursor-pointer space-y-3 wow fadeInUp lg:items-center sm:items-start">
                <li><ScrollLink to="home" smooth={true} duration={500} spy={true} offset={-70}>&gt; Home</ScrollLink></li>
                <li><ScrollLink to="aboutus" smooth={true} duration={500} spy={true} offset={-70}>&gt; About</ScrollLink></li>
                <li><ScrollLink to="projects" smooth={true} duration={500} spy={true} offset={-70}>&gt; Projects</ScrollLink></li>
                <li><ScrollLink to="easyflow" smooth={true} duration={500} spy={true} offset={-70}>&gt; Easy Process</ScrollLink></li>
                <li><ScrollLink to="nearfuture" smooth={true} duration={500} spy={true} offset={-70}>&gt; The Near Future</ScrollLink></li>
              </ul>
            </div>
            <div className="text-left">
              <h4 className="text-lg  ml-3 font-bold mb-2 wow fadeInUp">Services</h4>
              <ul className="font-normal text-base space-y-3 wow fadeInUp">
                <li> &gt; Software Development</li>
                <li> &gt; AI Programmer</li>
                <li> &gt; System Applications</li>
                <li> &gt; Network Solution</li>
              </ul>
            </div>
            <div className="text-left">
              <img src={nascom} alt="" className="-mt-10"/>
            </div>
          </div>
        </div>
        <div className="text-center mt-15">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Copyright by Corvanta Analytics –
            All right reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

const SocialLink = ({ href, icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="transform transition flex mb-3 items-center justify-center duration-500 hover:scale-125 text-white hover:text-gray-200 ring-1 ring-[#ffffff] rounded-full h-10 w-10 "
  >
    {icon }
  </a>
);

export default Footer;
